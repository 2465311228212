/* tslint:disable */
/* eslint-disable */
import {
  axiosInstance,
  timeout5SecAxiosInstance,
  PromiseAxiosResponse,
} from '@/worker/commands/config/apiInstance';
import { ResponseType } from 'axios';
import {
  AlertBarChartResponse,
  AlertClearRequest,
  AlertCurrentResponse,
  AlertHistoryResponse,
  AlertRuleDetailResponse,
  AlertRuleListResponse,
  ResultResponse,
} from '../model';
/* tslint:enable */
/* eslint-enable */

const getAxiosInstanceWrapper = ({
  httpMethod,
  localVarPath,
  data = undefined,
  config = {},
  isTimeout = false,
}: {
  httpMethod: string;
  localVarPath: string;
  data?: any;
  config?: any;
  isTimeout?: boolean;
}) => {
/* eslint-disable */
  const instance = isTimeout ? timeout5SecAxiosInstance : axiosInstance;
  if (['request'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](config);
  }
  if (['get', 'delete', 'head', 'options'].includes(httpMethod)) {
    // @ts-ignore
    return instance[httpMethod](localVarPath, config);
  }
  // @ts-ignore
  return instance[httpMethod](localVarPath, data, config);
  /* eslint-enable */
};

const getAlertRuleControllerAxios = ({
  alertGroupIds,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertGroupIds: Array<string>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertRuleListResponse> => {
  const localVarPath = '/api/v6/alert-groups/alert-rules';
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      alertGroupIds,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getDetailAlertRuleControllerAxios = ({
  alertRuleId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertRuleDetailResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getCurrentAlertRuleControllerAxios = ({
  alertRuleId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertCurrentResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}/current'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHistoryBarChartAlertRuleControllerAxios = ({
  alertRuleId,
  fromTime,
  period,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  fromTime?: string;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p14d'
    | 'p1d'
    | 'p1h'
    | 'p30d'
    | 'p30m'
    | 'p3d'
    | 'p3h'
    | 'p5m'
    | 'p6h'
    | 'p7d';
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertBarChartResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}/historyBarChart'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      period,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const getHistoryAlertRuleControllerAxios = ({
  alertRuleId,
  fromTime,
  isReport,
  period,
  timezone,
  toTime,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  fromTime?: string;
  isReport?: boolean;
  period?:
    | 'p10m'
    | 'p12h'
    | 'p14d'
    | 'p1d'
    | 'p1h'
    | 'p30d'
    | 'p30m'
    | 'p3d'
    | 'p3h'
    | 'p5m'
    | 'p6h'
    | 'p7d';
  timezone?: string;
  toTime?: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<AlertHistoryResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}/history'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'get';

  const data: any = {};
  const config: any = {
    params: {
      fromTime,
      isReport,
      period,
      timezone,
      toTime,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const activationAlertRuleControllerAxios = ({
  activation,
  alertRuleId,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  activation: boolean;
  alertRuleId: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}/activation'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      activation,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const cloneAlertRuleControllerAxios = ({
  alertRuleId,
  cloneName,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  cloneName: string;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}/clone'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      cloneName,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const muteAlertRuleControllerAxios = ({
  alertRuleId,
  mute,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  alertRuleId: string;
  mute: boolean;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/alert-rules/{alertRuleId}/mute'.replace(
    `{${'alertRuleId'}}`,
    encodeURIComponent(String(alertRuleId)),
  );
  const httpMethod = 'patch';

  const data: any = {};
  const config: any = {
    params: {
      mute,
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

const clearAlertRuleControllerAxios = ({
  clearRequests,
  signal,
  frameName,
  isTimeout,
  isPolling,
  responseType,
}: {
  clearRequests: Array<AlertClearRequest>;
  signal?: AbortController['signal'];
  frameName?: string;
  isTimeout?: boolean;
  isPolling?: boolean;
  responseType?: ResponseType;
}): PromiseAxiosResponse<ResultResponse> => {
  const localVarPath = '/api/v6/alert-rules/clear';
  const httpMethod = 'post';

  const data: any = clearRequests;
  const config: any = {
    params: {
      frameName,
      isPolling,
    },
    signal,
    responseType: responseType ?? 'json',
  };
  return getAxiosInstanceWrapper({
    httpMethod,
    localVarPath,
    data,
    config,
    isTimeout,
  });
};

export {
  getAlertRuleControllerAxios,
  getDetailAlertRuleControllerAxios,
  getCurrentAlertRuleControllerAxios,
  getHistoryBarChartAlertRuleControllerAxios,
  getHistoryAlertRuleControllerAxios,
  activationAlertRuleControllerAxios,
  cloneAlertRuleControllerAxios,
  muteAlertRuleControllerAxios,
  clearAlertRuleControllerAxios,
};
