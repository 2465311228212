import { createRouter, createWebHistory, parseQuery, RouteRecordRaw } from 'vue-router';
import qs from 'qs';
import { ssoMenu } from '@/sso/router/routes';
import { loginMenu } from '@/login/router/routes';
import { dashboardMenu } from '@/dashboard/router/routes';
import { applicationMenu, rumMenu } from '@/application/router/routes';
import { infrastructureMenu } from '@/infrastructure/router/routes';
import { kubernetesMenu } from '@/kubernetes/router/routes';
import { databaseMenu } from '@/database/router/routes';
import { pgMenu } from '@/postgresql/router/routes';
import { oracleMenu } from '@/oracle/router/routes';
import { mysqlMenu } from '@/mysql/router/routes';
import { sqlServerMenu } from '@/sqlServer/router/routes';
import { reportMenu } from '@/report/router/routes';
import { configMenu } from '@/config/router/routes';
import {
  requireAuth,
  setDayjsTimezone,
  skipLogin,
  ControlPageLoading,
  clearModule,
} from '@/common/router/navigationGuard';
import { faultAnalysisMenu } from '@/analysisTools/router/routers';
import { toolMenu } from '@/tool/router/routes';
import ErrorViewPort from '@/common/components/templates/ErrorViewPort.vue';
import { alertMenu } from '@/alert/router/routes';
import { businessMenu } from '@/service/router/router';
import { logsMenu } from '@/logs/router/routes';
import { helpMenu } from '@/help/router/routes';
import { addMessageEvent } from '@/common/utils/browserPopupUtils';
import PageLoading from '@/common/views/PageLoading.vue';
import type { Role } from '@/common/auth/auth.defines';
import type { RolePermissionKeyType } from '@/config/views/permission/rolePermission/rolePermission.type';
import { checkConnectionStateAtBeforeEnter } from '@/common/utils/connectionStatus.utils';
import type { ViewMode } from '@/common/stores/view-mode';
import { cloudMenu } from '@/cloud/router/routers';
import { messageQueueMenu } from '@/messageQueue/router/routes';

declare module 'vue-router' {
  interface RouteMeta {
    roles?: Role[];
    rolePermission?: RolePermissionKeyType;
    mode?: 'view' | 'edit';
    menuIcon?: string; // GNB panel에서 렌더링할 아이콘명
    invisibleViewMode?: ViewMode[];
  }
}

const getConvertedProperties = (query: Record<string, any>) => {
  const result = {};
  Object.keys(query).forEach((v) => {
    if (typeof query[v] === 'string') {
      const value = query[v].toLowerCase();
      if (value === 'true' || value === 'false') {
        result[v] = value === 'true';
      } else if (value && !Number.isNaN(Number(value))) {
        result[v] = Number(value);
      }
    }
  });
  return result;
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "login" */ '../../login/views/Login.vue'),
    beforeEnter: skipLogin,
  },
  {
    path: '/exemone.html',
    component: () => import(/* webpackChunkName: "login" */ '../../login/views/Login.vue'),
    beforeEnter: skipLogin,
  },
  {
    path: '/manager',
    component: () => import(/* webpackChunkName: "manager-login" */ '../../login/views/Login.vue'),
    beforeEnter: clearModule,
    props: () => ({ isManager: true }),
  },
  ...ssoMenu,
  ...loginMenu,
  ...dashboardMenu,
  ...applicationMenu,
  ...rumMenu,
  ...infrastructureMenu,
  ...messageQueueMenu,
  ...cloudMenu,
  ...kubernetesMenu,
  ...databaseMenu,
  ...businessMenu,
  ...alertMenu,
  ...pgMenu,
  ...oracleMenu,
  ...mysqlMenu,
  ...sqlServerMenu,
  ...reportMenu,
  ...configMenu,
  ...faultAnalysisMenu,
  ...logsMenu,
  ...toolMenu,
  ...helpMenu,
  {
    path: '/loading',
    component: PageLoading,
  },
  {
    path: '/401',
    component: ErrorViewPort,
    children: [
      {
        name: 'PageAuthFail',
        path: '',
        component: () =>
          import(/* webpackChunkName: "pageForbidden" */ '../views/PageAuthFail.vue'),
      },
    ],
  },
  {
    path: '/403',
    component: ErrorViewPort,
    children: [
      {
        name: 'PageForbidden',
        path: '',
        component: () =>
          import(/* webpackChunkName: "pageForbidden" */ '../views/PageForbidden.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    component: ErrorViewPort,
    children: [
      {
        name: 'PageNotFound',
        path: '',
        component: () => import(/* webpackChunkName: "pageNotFound" */ '../views/PageNotFound.vue'),
      },
    ],
  },
  {
    path: '/popup',
    name: 'BrowserPopup',
    component: () =>
      import(
        /* webpackChunkName: "browser-popup" */ '../components/templates/browserPopup/BrowserPopup.vue'
      ),
    props: (route) => {
      const { query } = route;
      return {
        ...query,
        ...getConvertedProperties(query),
        isPopup: true,
      };
    },
    beforeEnter: [requireAuth, setDayjsTimezone],
  },
  // [TODO] 임시로 styleGuide를 위한 라우터 추가
  {
    path: '/styleGuide',
    name: 'StyleGuide',
    component: () => import(/* webpackChunkName: "style-guide" */ '@/styleGuide/StyleGuide.vue'),
  },
  {
    path: '/test',
    name: 'test',
    component: () =>
      import(
        /* webpackChunkName: "grid-test" */ '@/application/views/alertHistory/AlertHistory.vue'
      ),
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  },
  parseQuery: qs.parse as typeof parseQuery,
  stringifyQuery: qs.stringify,
});

router.beforeResolve((to, from, next) => {
  if (!to.path.includes('popup')) {
    addMessageEvent();
  }
  ControlPageLoading.inactive();
  checkConnectionStateAtBeforeEnter(to);
  next();
});

export default router;
