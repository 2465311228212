import { VIEW_MODE } from '@/common/define/common.define';

export const BASE_MENU_VALUE = {
  DASHBOARD: 'dashboard',
  ANALYSISBOARD: 'analysisBoard',
  INFRASTRUCTURE: 'infrastructure',
  MESSAGE_QUEUE: 'message-queue',
  KUBERNETES: 'kubernetes',
  CLOUD: 'cloud',
  APPLICATION: 'application',
  DATABASE: 'database',
  BUSINESS: 'business',
  ALERT: 'alert',
  PA: 'pa',
  TOOL: 'tool',
  REPORT: 'report',
  LOGS: 'logs', // 로그 (2024.02)
  HELP: 'help', // 고객지원 (2024.05)
  SETTINGS: 'myInfo',

  // DB_TYPE 불러오면 순환참조돼서 오류 납니다
  FAULT_ANALYSIS: 'fault-analysis',
  ANALYSIS: 'analysis',
  ORACLE: 'oracle',
  POSTGRESQL: 'postgresql',
  MYSQL: 'mysql',
  SQLSERVER: 'sqlserver',
  REDIS: 'redis',
  CUBRID: 'cubrid',
  MONGODB: 'mongodb',
  TIBERO: 'tibero',
  ALTIBASE: 'altibase',
} as const;

export const SUBTREE_ARRAY = ['children', 'subMenuList'] as const;

export const POPOVER_MENU_TYPES = {
  HELP: {
    value: BASE_MENU_VALUE.HELP,
    selector: '.user-support-popup',
  },
  SETTINGS: {
    value: BASE_MENU_VALUE.SETTINGS,
    selector: '.my-info-popup',
  },
} as const;

type ViewMode = (typeof VIEW_MODE)[keyof typeof VIEW_MODE];

export const VIEW_MODE_SERVICE: Record<ViewMode, string[]> = {
  exemone: ['system', 'k8s', 'application', 'database'],
  maxgauge: ['database'],
  intermax: ['system', 'application'],
  cloudmoa: ['system', 'k8s'],
};

const PA_DB_TYPE = {
  ORACLE: 'oracle',
  POSTGRESQL: 'postgresql',
  MYSQL: 'mysql',
  SQLSERVER: 'sqlserver',
};

export const MAXGAUGE_DATABASE_MENU_KEY_MAPPER = {
  [PA_DB_TYPE.ORACLE]: {
    [`${PA_DB_TYPE.ORACLE}_SQL Analysis`]: {
      menuKey: 'sqlAnalysis',
      menuTextKey: 'WORD.GNB.SQL_ANALYSIS',
    },
    [`${PA_DB_TYPE.ORACLE}_Event Analysis`]: {
      menuKey: 'eventAnalysis',
      menuTextKey: 'WORD.GNB.EVENT_ANALYSIS',
    },
    [`${PA_DB_TYPE.ORACLE}_Session Analysis`]: {
      menuKey: 'sessionAnalysis',
      menuTextKey: 'WORD.GNB.SESSION_ANALYSIS',
    },
    [`${PA_DB_TYPE.ORACLE}_Performance Analysis`]: {
      menuKey: 'performanceAnalysis',
      menuTextKey: 'WORD.GNB.PERFORMANCE_ANALYSIS',
    },
    [`${PA_DB_TYPE.ORACLE}_DataSpace Analysis`]: {
      menuKey: 'dataSpaceAnalysis',
      menuTextKey: 'WORD.GNB.DATASPACE_ANALYSIS',
    },
  },
  [PA_DB_TYPE.POSTGRESQL]: {
    [`${PA_DB_TYPE.POSTGRESQL}_SQL Analysis`]: {
      menuKey: 'sqlAnalysis',
      menuTextKey: 'WORD.GNB.SQL_ANALYSIS',
    },
    [`${PA_DB_TYPE.POSTGRESQL}_Session Analysis`]: {
      menuKey: 'sessionAnalysis',
      menuTextKey: 'WORD.GNB.SESSION_ANALYSIS',
    },
    [`${PA_DB_TYPE.POSTGRESQL}_Performance Analysis`]: {
      menuKey: 'performanceAnalysis',
      menuTextKey: 'WORD.GNB.PERFORMANCE_ANALYSIS',
    },
    [`${PA_DB_TYPE.POSTGRESQL}_DataSpace Analysis`]: {
      menuKey: 'dataSpaceAnalysis',
      menuTextKey: 'WORD.GNB.DATASPACE_ANALYSIS',
    },
  },
  [PA_DB_TYPE.MYSQL]: {
    [`${PA_DB_TYPE.MYSQL}_SQL Analysis`]: {
      menuKey: 'sqlAnalysis',
      menuTextKey: 'WORD.GNB.SQL_ANALYSIS',
    },
    [`${PA_DB_TYPE.MYSQL}_Session Analysis`]: {
      menuKey: 'sessionAnalysis',
      menuTextKey: 'WORD.GNB.SESSION_ANALYSIS',
    },
    [`${PA_DB_TYPE.MYSQL}_Performance Analysis`]: {
      menuKey: 'performanceAnalysis',
      menuTextKey: 'WORD.GNB.PERFORMANCE_ANALYSIS',
    },
    [`${PA_DB_TYPE.MYSQL}_DataSpace Analysis`]: {
      menuKey: 'dataSpaceAnalysis',
      menuTextKey: 'WORD.GNB.DATASPACE_ANALYSIS',
    },
  },
  [PA_DB_TYPE.SQLSERVER]: {
    [`${PA_DB_TYPE.SQLSERVER}_SQL Analysis`]: {
      menuKey: 'sqlAnalysis',
      menuTextKey: 'WORD.GNB.SQL_ANALYSIS',
    },
    [`${PA_DB_TYPE.SQLSERVER}_Session Analysis`]: {
      menuKey: 'sessionAnalysis',
      menuTextKey: 'WORD.GNB.SESSION_ANALYSIS',
    },
    [`${PA_DB_TYPE.SQLSERVER}_Performance Analysis`]: {
      menuKey: 'performanceAnalysis',
      menuTextKey: 'WORD.GNB.PERFORMANCE_ANALYSIS',
    },
    [`${PA_DB_TYPE.SQLSERVER}_DataSpace Analysis`]: {
      menuKey: 'dataSpaceAnalysis',
      menuTextKey: 'WORD.GNB.DATASPACE_ANALYSIS',
    },
  },
} as const;
