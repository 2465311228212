import { RouteRecordRaw } from 'vue-router';
import RtmViewport from '@/common/components/templates/RtmViewport.vue';
import { PATH, requireAuth, setDayjsTimezone } from '@/common/router/navigationGuard';
import { EvMessage } from 'evui';
import { i18n } from '@/common/locale';
import { getBoardsIntegratedDashboardV8ControllerAxios } from '@/openapi/dashboard/api/integrated-dashboard-v8-controller-api';
import { store } from '@/common/store';
import { checkRole } from '@/common/auth/auth.utils';
import { deniedDashboard, deniedToRouteToEditDashboard } from '../utils/permission';

const checkDashboardExistById = async (to) => {
  const { t } = i18n.global;
  const myRole = store.getters['myInfo/getAccountInfo'].role;
  const isMaster = checkRole.isMaster(myRole);

  const dashboardId = to.params.id;
  if (dashboardId) {
    const { data } = await getBoardsIntegratedDashboardV8ControllerAxios({
      dashboardId,
      includeImage: false,
    });
    if (data.data?.length) {
      if (isMaster) {
        return undefined;
      }

      const { preset, featuredReport, shared, createUser } = data.data[0];
      if (
        deniedDashboard({
          isMyDashboard: !!createUser,
          isFeatured: preset,
          isFeatureReport: featuredReport,
        })
      ) {
        EvMessage({
          type: 'error',
          message: t('NOTI.API.NOT_PERMISSION'),
        });
        return PATH.DASHBOARD_LIST;
      }

      if (to.meta.mode === 'edit') {
        const deniedToAccessEdit = deniedToRouteToEditDashboard({
          isMyDashboard: !!createUser,
          isFeatured: preset,
          isFeatureReport: featuredReport,
        });
        const isEnableEdit = !deniedToAccessEdit && (createUser || (shared && !createUser));
        if (!isEnableEdit) {
          EvMessage({
            type: 'error',
            message: deniedToAccessEdit
              ? t('NOTI.API.NOT_PERMISSION')
              : t('MESSAGE.DASHBOARD_LIST.NO_PERMISSION_DASHBOARD'),
          });
          return PATH.DASHBOARD_LIST;
        }
        return undefined;
      }
      return undefined;
    }
  }
  EvMessage({
    type: 'error',
    message: t('MESSAGE.DASHBOARD_LIST.NO_PERMISSION_DASHBOARD'),
  });
  return PATH.DASHBOARD_LIST;
};

const PREFIX_DASHBOARD = 'dashboard_';

const dashboardMenu: RouteRecordRaw[] = [
  {
    path: '/dashboard/',
    name: `${PREFIX_DASHBOARD}Dashboard`,
    component: RtmViewport,
    children: [
      {
        path: 'list',
        name: `${PREFIX_DASHBOARD}Dashboard List`,
        component: () =>
          import(
            /* webpackChunkName: "custom-dashboard-list-view" */ '@/dashboard/views/dashboardListView/DashboardListView.vue'
          ),
        beforeEnter: [requireAuth, setDayjsTimezone],
      },
      {
        path: 'view/:id',
        name: `${PREFIX_DASHBOARD}Dashboard View`,
        component: () =>
          import(
            /* webpackChunkName: "custom-dashboard-view" */ '../views/dashboardView/DashboardView.vue'
          ),
        beforeEnter: [requireAuth, setDayjsTimezone, checkDashboardExistById],
        meta: { mode: 'view' },
      },
      {
        path: 'editor/:id*',
        name: `${PREFIX_DASHBOARD}Dashboard Editor View`,
        component: () =>
          import(
            /* webpackChunkName: "custom-dashboard-widget" */ '../views/dashboardEditorView/DashboardEditorView.vue'
          ),
        beforeEnter: [requireAuth, setDayjsTimezone, checkDashboardExistById],
        meta: { mode: 'edit' },
      },
    ],
  },
];

const getDashboardNaviMenu = () => {
  const menus = [...dashboardMenu];
  if (menus?.[0].children?.length) {
    menus[0].children = menus[0].children.filter(
      ({ path }) => path !== 'view/:id' && path !== 'editor/:id*',
    );
  }

  return menus;
};

export { dashboardMenu, getDashboardNaviMenu };
