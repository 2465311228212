import { defineAsyncComponent } from 'vue';

export const KUBER_DETAIL = {
  NODE_LIST: 'Node List',
  NAMESPACE_LIST: 'Namespace List',
  POD_LIST: 'Pod List',
  SERVICE_OBJECT_LIST: 'Service Object List',
  INGRESS_LIST: 'Ingress List',
  PERSISTENT_VOLUMES_LIST: 'Persistent Volumes List',
  PERSISTENT_VOLUMES_CLAIMS_LIST: 'Persistent Volumes Claims List',
  STORAGE_CLASSES_LIST: 'Storage Classes List',
  NAMESPACE: 'Namespace',
  POD: 'Pod',
  NODE: 'Node',
  CRON_JOB: 'CronJobs',
  OWNER_REFERENCES: 'Owner References',
  DEPLOYMENT: 'Deployments',
  STATEFUL_SET: 'Stateful Sets',
  DAEMON_SET: 'Daemon Sets',
  JOB: 'Job',
  REPLICA_SET: 'Replica Sets',
  SERVICE: 'Service',
  ROLES_BINDINGS: 'Role Binding',
  SERVICE_ACCOUNTS: 'Service Accounts',
  ROLES: 'Role',
  INGRESS: 'Ingress',
  CONFIG_MAPS: 'ConfigMaps',
  PERSISTENT_VOLUMES_CLAIMS: 'Persistent Volumes Claims',
  STORAGE_CLASSES: 'Storage Classes',
  CLUSTER_ROLES: 'Cluster Roles',
  CLUSTER_ROLES_BINDINGS: 'Cluster Role Binding',
  PERSISTENT_VOLUMES: 'Persistent Volumes',
  HPA: 'HPA',
} as const;

export const INFRA_DETAIL = {
  HOST: 'Host',
  CONTAINER: 'Container',
  NETWORK_DEVICE: 'Network Device',
  PROCESS_DETAIL: 'Process Detail',
  NETWORK_DETAIL: 'Network Detail',
  DISK_DETAIL: 'Disk Detail',
  NETWORK_OBJECT: 'Network Object',
  SCRIPT_DETAIL: 'Script',
} as const;

export const KAFKA_DETAIL = {
  KAFKA_CLUSTER: 'Kafka Cluster',
  KAFKA_BROKER: 'Kafka Broker',
  KAFKA_TOPIC: 'Kafka Topic',
} as const;
export const MESSAGE_QUEUE_DETAIL = {
  ...KAFKA_DETAIL,
} as const;

export const APPLICATION_DETAIL = {
  WAS: 'WAS',
  TRANSACTION: 'Transaction',
  ACTIVE_TRANSACTION: 'Active Transaction',
  WEB_APPLICATION: 'Web Application',
  WEB_APPLICATION_PAGE_ANALYSIS: 'Web Application Page Analysis',
  AJAX_REQUEST_HISTORY: 'Ajax Request History',
  URL_MONITORING: 'URL',
  BROWSER_ERROR_TRACKING: 'Browser Error Tracking',
  PAGE_PERFORMANCE_MONITORING: 'Page Performance Monitoring',
  USER_MONITORING_SESSION_ANALYSIS: 'User Monitoring Session Analysis',
  USER_MONITORING_SESSION_REPLAY: 'User Monitoring Session Replay',
  USER_MONITORING_USER_EVENT: 'User Monitoring User Event',
} as const;

export const ALERT_DETAIL = {
  ALERT: 'Alert',
} as const;

export const SERVICE_DETAIL = {
  SERVICE_LIST: 'Business List',
} as const;

export const DATABASE_DETAIL = {
  INSTANCE: 'Instance',
  SESSION: 'Session',
  SQL: 'SQL',
  PARAMETER: 'Parameter',
  CLUSTER: 'Cluster',
  BROKER: 'Broker',
  SHARDED_CLUSTER: 'Sharded Cluster',
  KEY_BROWSER: 'Key',
  OBJECT: 'Object',
} as const;

export const TOOL_DETAIL = {
  QUERY: 'Query',
} as const;

export const CONFIG_DETAIL = {
  AGENT: 'Agent',
  EXECUTION: 'Execution',
  USAGE_TREND: 'Usage Trend',
} as const;

export const CLOUD_DETAIL = {
  AWS_EC2: 'AWS EC2',
  AWS_LAMBDA: 'AWS Lambda',
  AWS_S3: 'AWS S3',
  AWS_DOCUMENTDB: 'AWS DocumentDb',
  AWS_DYNAMODB: 'AWS DynamoDB',
  AWS_ELASTICACHE: 'AWS ElastiCache',
  AWS_RDS: 'AWS RDS',
  AWS_DMS_TASK: 'AWS DMS Task',
  AWS_DMS_REPLINSTANCE: 'AWS DMS Replication Instance',
  AWS_REDSHIFT: 'AWS Redshift',
} as const;

export const CUSTOM_DETAIL = {
  CUSTOM: 'CUSTOM',
} as const;

export const DETAIL_COMPONENTS = {
  // cluster topology - list detail
  [KUBER_DETAIL.NODE_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/NodeListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.NAMESPACE_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/NamespaceListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.POD_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/PodListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.SERVICE_OBJECT_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/ServiceListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.INGRESS_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/IngressListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES_CLAIMS_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/PvcListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/PvListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.STORAGE_CLASSES_LIST]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/listDetail/ScListDetail.vue'),
    ),
  },
  // kuber
  [KUBER_DETAIL.NAMESPACE]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/namespaceDetail/NamespaceDetail.vue'),
    ),
  },
  [KUBER_DETAIL.POD]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/podsListDetail/PodsListDetail.vue'),
    ),
  },
  [KUBER_DETAIL.NODE]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/nodeDetail/NodeDetail.vue'),
    ),
  },
  [KUBER_DETAIL.SERVICE]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/serviceDetail/ServiceDetail.vue'),
    ),
  },
  [KUBER_DETAIL.DEPLOYMENT]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/deploymentsDetail/DeploymentsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.STATEFUL_SET]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/statefulSetsDetail/StatefulSetsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.REPLICA_SET]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/replicaSetsDetail/ReplicaSetsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.DAEMON_SET]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/daemonSetsDetail/DaemonSetsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.ROLES_BINDINGS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/rolesBindingsDetail/RolesBindingsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.ROLES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/rolesDetail/RolesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.HPA]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/hpaDetail/HpaDetail.vue'),
    ),
  },
  [KUBER_DETAIL.JOB]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/jobsDetail/JobsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.INGRESS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/ingressDetail/IngressDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CRON_JOB]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/cronJobsDetail/CronJobsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.SERVICE_ACCOUNTS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/serviceAccountsDetail/ServiceAccountsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CONFIG_MAPS]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/configMapsDetail/ConfigMapsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.STORAGE_CLASSES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/storageClassesDetail/StorageClassesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CLUSTER_ROLES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/clusterRolesDetail/ClusterRolesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.CLUSTER_ROLES_BINDINGS]: {
    component: defineAsyncComponent(
      () =>
        import('@/kubernetes/components/clusterRolesBindingsDetail/ClusterRolesBindingsDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES]: {
    component: defineAsyncComponent(
      () => import('@/kubernetes/components/persistentVolumesDetail/PersistentVolumesDetail.vue'),
    ),
  },
  [KUBER_DETAIL.PERSISTENT_VOLUMES_CLAIMS]: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/kubernetes/components/persistentVolumesClaimsDetail/PersistentVolumesClaimsDetail.vue'
        ),
    ),
  },
  // infra
  [INFRA_DETAIL.HOST]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/HostDetail.vue'),
    ),
  },
  [INFRA_DETAIL.CONTAINER]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/containerView/containerDetail/ContainerDetail.vue'),
    ),
  },
  [INFRA_DETAIL.NETWORK_DEVICE]: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/infrastructure/views/networkDeviceView/networkDeviceDetail/NetworkDeviceDetail.vue'
        ),
    ),
  },
  [INFRA_DETAIL.PROCESS_DETAIL]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/ProcessDetail.vue'),
    ),
  },
  [INFRA_DETAIL.NETWORK_DETAIL]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/NetworkDetail.vue'),
    ),
  },
  [INFRA_DETAIL.DISK_DETAIL]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/hostView/hostDetail/DiskDetail.vue'),
    ),
  },
  [INFRA_DETAIL.NETWORK_OBJECT]: {
    component: defineAsyncComponent(
      () => import('@/infrastructure/views/npm/networkObject/NetworkObjectDetail.vue'),
    ),
  },
  [INFRA_DETAIL.SCRIPT_DETAIL]: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/infrastructure/views/scriptManagerHistoryView/scriptManagerHistoryDetail/ScriptManagerHistoryDetail.vue'
        ),
    ),
  },
  // message-queue
  [MESSAGE_QUEUE_DETAIL.KAFKA_CLUSTER]: {
    component: defineAsyncComponent(
      () => import('@/messageQueue/views/kafka/kafkaDetail/KafkaClusterDetail.vue'),
    ),
  },
  [MESSAGE_QUEUE_DETAIL.KAFKA_BROKER]: {
    component: defineAsyncComponent(
      () => import('@/messageQueue/views/kafka/kafkaDetail/KafkaBrokerDetail.vue'),
    ),
  },
  [MESSAGE_QUEUE_DETAIL.KAFKA_TOPIC]: {
    component: defineAsyncComponent(
      () => import('@/messageQueue/views/kafka/kafkaDetail/KafkaTopicDetail.vue'),
    ),
  },
  // application
  [APPLICATION_DETAIL.WAS]: {
    component: defineAsyncComponent(
      () => import('@/application/components/wasDetail/WasDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.TRANSACTION]: {
    component: defineAsyncComponent(
      () => import('@/application/components/transactionDetail/TransactionDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.ACTIVE_TRANSACTION]: {
    component: defineAsyncComponent(
      () => import('@/application/components/activeTransactionDetail/ActiveTransactionDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.URL_MONITORING]: {
    component: defineAsyncComponent(
      () => import('@/application/components/urlMonitoringDetail/UrlMonitoringDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.AJAX_REQUEST_HISTORY]: {
    component: defineAsyncComponent(
      () =>
        import('@/application/components/ajaxRequestHistoryDetail/AjaxRequestHistoryDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.WEB_APPLICATION_PAGE_ANALYSIS]: {
    component: defineAsyncComponent(
      () => import('@/application/components/pageAnalysisDetail/PageAnalysisDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.WEB_APPLICATION]: {
    component: defineAsyncComponent(
      () => import('@/application/components/webApplicationDetail/WebApplicationDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.BROWSER_ERROR_TRACKING]: {
    component: defineAsyncComponent(
      () =>
        import(
          '@/application/components/browserErrorTrackingDetail/BrowserErrorTrackingDetail.vue'
        ),
    ),
  },
  [APPLICATION_DETAIL.PAGE_PERFORMANCE_MONITORING]: {
    component: defineAsyncComponent(
      () => import('@/application/components/pagePerformanceDetail/PagePerformanceDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.USER_MONITORING_SESSION_ANALYSIS]: {
    component: defineAsyncComponent(
      () => import('@/application/components/sessionAnalysisDetail/SessionAnalysisDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.USER_MONITORING_SESSION_REPLAY]: {
    component: defineAsyncComponent(
      () => import('@/application/components/sessionReplayDetail/SessionReplayDetail.vue'),
    ),
  },
  [APPLICATION_DETAIL.USER_MONITORING_USER_EVENT]: {
    component: defineAsyncComponent(
      () => import('@/application/components/userEventDetail/UserEventDetail.vue'),
    ),
  },
  [ALERT_DETAIL.ALERT]: {
    component: defineAsyncComponent(() => import('@/alert/components/alertDetail/AlertDetail.vue')),
  },
  [SERVICE_DETAIL.SERVICE_LIST]: {
    component: defineAsyncComponent(
      () => import('@/service/views/serviceView/serviceDetail/ServiceDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.INSTANCE]: {
    component: defineAsyncComponent(
      () => import('@/database/components/instanceDetail/InstanceDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.SQL]: {
    component: defineAsyncComponent(
      () => import('@/database/components/sqlDetail/InstanceSqlDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.SESSION]: {
    component: defineAsyncComponent(
      () => import('@/database/components/sessionDetail/InstanceSessionDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.PARAMETER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/parameterDetail/InstanceParameterDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.CLUSTER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/clusterDetail/InstanceClusterDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.BROKER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/brokerDetail/BrokerDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.SHARDED_CLUSTER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/instanceDetail/InstanceDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.KEY_BROWSER]: {
    component: defineAsyncComponent(
      () => import('@/database/components/keyBrowserDetail/KeyBrowserDetail.vue'),
    ),
  },
  [DATABASE_DETAIL.OBJECT]: {
    component: defineAsyncComponent(
      () => import('@/postgresql/views/objectSize/objectDetail/ObjectDetail.vue'),
    ),
  },

  [TOOL_DETAIL.QUERY]: {
    component: defineAsyncComponent(() => import('@/tool/components/queryDetail/QueryDetail.vue')),
  },

  // Config
  [CONFIG_DETAIL.USAGE_TREND]: {
    component: defineAsyncComponent(
      () => import('@/config/views/management/dataRetention/components/UsageTrendSlide.vue'),
    ),
  },
  [CONFIG_DETAIL.AGENT]: {
    component: defineAsyncComponent(
      () => import('@/config/components/agentDetail/AgentDetail.vue'),
    ),
  },
  [CONFIG_DETAIL.EXECUTION]: {
    component: defineAsyncComponent(
      () => import('@/config/components/executionDetail/ExecutionDetail.vue'),
    ),
  },

  // Cloud
  [CLOUD_DETAIL.AWS_EC2]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/ec2View/ec2Detail/AwsEc2Detail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_LAMBDA]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/lambdaView/lambdaDetail/AwsLambdaDetail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_S3]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/s3View/s3Detail/AwsS3Detail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_DOCUMENTDB]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/documentDbView/documentDbDetail/AwsDocumentDbDetail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_DYNAMODB]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/dynamoDbView/dynamoDbDetail/AwsDynamoDbDetail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_ELASTICACHE]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/elastiCacheView/elastiCacheDetail/AwsElastiCacheDetail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_RDS]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/rdsView/rdsDetail/AwsRdsDetail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_DMS_TASK]: {
    component: defineAsyncComponent(() => import('@/cloud/views/aws/dms/detail/AwsDmsDetail.vue')),
  },
  [CLOUD_DETAIL.AWS_DMS_REPLINSTANCE]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/dms/detail/AwsDmsReplicationDetail.vue'),
    ),
  },
  [CLOUD_DETAIL.AWS_REDSHIFT]: {
    component: defineAsyncComponent(
      () => import('@/cloud/views/aws/redshiftView/redshiftDetail/AwsRedshiftDetail.vue'),
    ),
  },
};
