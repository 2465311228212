import { ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { getSlideDetailStore } from '@/common/stores/slide-detail';
import { getAlertDetailProps, getAlertType } from '@/alert/utils/utils';
import { RuleInfoProps } from '@/alert/components/alertDetail/alertDetail.types';
import { isEqual } from 'lodash-es';
import { useInternational } from '@/common/locale';
import { useTargetDetailProvide } from '@/alert/components/alertTargetList/alertTargetList.uses';
import { useAlertRule } from '@/alert/components/alertDetail/alertDetail.use';
import { useAlertDetailStore } from '@/alert/stores/alert-detail';

export interface Props {
  ruleInfo?: RuleInfoProps;
}
export interface Emit {
  (e: 'openDetail'): void;
  (e: 'clear'): void;
}

export const setup = (props: Props, emit: Emit) => {
  const { t } = useInternational();
  const { selectedSlideTabPanel } = storeToRefs(getSlideDetailStore());
  const { selectedRuleInfo } = storeToRefs(useAlertDetailStore());

  const isTabLoading = ref(false);
  const isLoadingRuleInfo = ref(false);

  const tabPanels = [
    {
      text: t('WORD.INFORMATION'),
      value: 'information',
    },
    {
      text: t('WORD.CURRENT'),
      value: 'current',
    },
    {
      text: t('WORD.HISTORY'),
      value: 'history',
    },
  ];
  const selectedTab = ref('information');
  const initSelectedTab = () => {
    selectedTab.value = 'information';
  };

  useTargetDetailProvide(emit);

  const { getUserAlertRule } = useAlertRule();
  const getRuleInfo = async ({
    name,
    ruleId,
    type,
  }: {
    name: string;
    ruleId?: string;
    type: string;
  }): Promise<RuleInfoProps> => {
    if (getAlertType(type) === 'user') {
      const { ruleCriteria, type: subType } = await getUserAlertRule({
        ruleId,
        fields: ['ruleCriteria', 'type'],
      });
      return {
        ...getAlertDetailProps({ name, ruleId, type }),
        ruleCriteria,
        subType: subType as RuleInfoProps['subType'],
      };
    }

    return {
      ...getAlertDetailProps({ name, ruleId, type }),
      subType: 'system',
    };
  };

  watch(
    [selectedSlideTabPanel, () => props.ruleInfo],
    async ([currTabInfo, currRuleInfoProp], [prevTabInfo, prevRuleInfoProp]) => {
      if (currTabInfo?.type === 'Alert' && !currRuleInfoProp) {
        isLoadingRuleInfo.value = true;
        selectedRuleInfo.value = await getRuleInfo({
          name: currTabInfo?.name ?? '',
          ruleId: currTabInfo?.addition?.ruleId,
          type: currTabInfo?.addition?.type ?? 'user',
        });
        if (
          currTabInfo?.addition?.ruleId !== prevTabInfo?.addition?.ruleId ||
          currTabInfo?.name !== prevTabInfo?.name
        ) {
          initSelectedTab();
        }
        isLoadingRuleInfo.value = false;
      } else if (currRuleInfoProp && !isEqual(currRuleInfoProp, prevRuleInfoProp)) {
        isLoadingRuleInfo.value = true;
        selectedRuleInfo.value = await getRuleInfo({
          name: currRuleInfoProp?.name ?? '',
          ruleId: currRuleInfoProp?.ruleId,
          type: currRuleInfoProp?.type ?? 'user',
        });
        initSelectedTab();
        isLoadingRuleInfo.value = false;
      }
    },
    { deep: true, immediate: true },
  );

  return {
    isTabLoading,
    tabPanels,
    selectedTab,
    isLoadingRuleInfo,
  };
};
