import { getStringByteLength } from '@/dashboard/utils/dashboardUtils';

export const getLocaleKey = (text: string): string =>
  text ? text.replace(/ /g, '_').replace(/_&_/g, '_').toUpperCase() : '';

export const getDetailKey = (text: string): string =>
  text ? text.replace(/ /g, '').replace(/_/g, '').toLowerCase() : '';

export const compressText = ({
  text,
  length = 100,
  suffix = '...',
}: {
  text: string;
  length?: number;
  suffix?: string;
}) => {
  if (text.length <= length) {
    return text;
  }
  return `${text.slice(0, length)}${suffix}`;
};

export const getPascalCase = (text: string, joinValue: string = ''): string => {
  return text
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(joinValue);
};

export const getJsonParse = (text: string): Record<any, any> | Array<any> | null => {
  try {
    const parsedData = JSON.parse(text);
    return parsedData;
  } catch (e) {
    return null;
  }
};

export const escapeLtGt = (text: string, exceptionTag?: 'mark'): string => {
  if (exceptionTag) {
    const escaped = text.replace(/</g, '&lt;').replace(/>/g, '&gt;');

    const openingTag = new RegExp(
      `&lt;(${exceptionTag} class="log-highlight-keyword" style="--keyword-bg-color: [^"]+; --keyword-line-color: [^"]+;")&gt;`,
      'g',
    );

    const closingTag = new RegExp(`&lt;\\/${exceptionTag}&gt;`, 'g');

    return escaped.replace(openingTag, '<$1>').replace(closingTag, `</${exceptionTag}>`);
  }
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

export const stringToBinary = (str?: string) => {
  if (!str) {
    return '';
  }

  return Array.from(str)
    .map((char) => char.codePointAt(0)!.toString(2))
    .join('');
};

export const adjustStringByMaxByte = (text: string, maxByte: number): string => {
  if (!text || !maxByte || getStringByteLength(text) <= maxByte) {
    return text;
  }

  let startIndex = 0;
  let endIndex = text.length;
  let result = text;

  while (startIndex <= endIndex) {
    const midIndex = Math.floor((startIndex + endIndex) / 2);
    const slice = text.slice(0, midIndex);
    const byteLength = getStringByteLength(slice);

    if (byteLength <= maxByte) {
      result = slice;
      startIndex = midIndex + 1;
    } else {
      endIndex = midIndex - 1;
    }
  }
  return result;
};
