import { defineStore } from 'pinia';
import { ref } from 'vue';
import { DashboardData } from '@/dashboard/types/dashboard';
import { WidgetChartData, WidgetChartType, WidgetTimePeriod } from '@/dashboard/utils/types';
import { WidgetTriggerPathType, WidgetUUID } from '@/common/utils/types';
import { FRAME_NAMES } from '@/common/define/apiTrace.define';
import { ScatterDetailRequest } from '@/openapi/data/model';
import { getWidgetMappingInfoDashboardWidgetV8ControllerAxios } from '@/openapi/metaV6/api/dashboard-widget-v8-controller-api';
import { WidgetV8MappingResponseItem } from '@/openapi/metaV6/model';
import { cloneDeep } from 'lodash-es';
import { getBoardsIntegratedDashboardV8ControllerAxios } from '@/openapi/dashboard/api/integrated-dashboard-v8-controller-api';
import type {
  SelectedLegends,
  MonitoringDashboardTarget,
} from '@/dashboard/stores/dashboard-legend';
import type { AllDashboardTargetsColumns } from '@/dashboard/stores/dashboard-targets';
import { convertToDashboardData, DEFAULT_DASHBOARD_DATA } from '@/dashboard/adaptors';

export interface TriggerDetailInfo {
  triggerPath: WidgetTriggerPathType;
  widgetId: WidgetUUID;
  targetId: string;
  targetName?: string;
  addition?: Record<string, any>;
}

export interface SummaryDetailData {
  type: 'scatter' | 'metric-stat' | 'current-stat';
  title: string;
  widgetId: WidgetUUID;
  chartType: WidgetChartType;
  chartData: WidgetChartData[];
  chartOption: any;
  colorTheme: string;
  timePeriod: WidgetTimePeriod;
  params: any;
  targetIds?: (string[] | undefined)[];
  argParam?: ScatterDetailRequest['argParam'][];
  selectedLegends?: SelectedLegends[] | null;
  targetsColumns?: AllDashboardTargetsColumns[];
  legendList?: Record<string, MonitoringDashboardTarget>;
  matchingThemeSeriesColor?: Record<string, Record<string, string>>;
  isIncludeData?: boolean;
}

export const useDashboardViewStore = defineStore('dashboardDashboardView', () => {
  const monitoringDashboard = ref<DashboardData>(cloneDeep(DEFAULT_DASHBOARD_DATA));

  const toggleShowTimePeriod = () => {
    monitoringDashboard.value.dashboardOption.showTimePeriod =
      !monitoringDashboard.value.dashboardOption.showTimePeriod;
  };

  const setGlobalPeriodRange = (value: string) => {
    monitoringDashboard.value.dashboardOption.periodRange = value;
  };

  const widgetsForMonitoringDashboard = ref<WidgetV8MappingResponseItem>();

  const fetchCurrentDashboardInfo = async (dashboardId: number) => {
    try {
      const { data } = await getBoardsIntegratedDashboardV8ControllerAxios({
        dashboardId,
        includeImage: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_INFO,
      });

      const dashboardInfo = data?.data?.at(0);

      if (dashboardInfo) {
        monitoringDashboard.value = convertToDashboardData(dashboardInfo);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchWidgetsForMonitoringDashboard = async (dashboardId: number) => {
    try {
      const { data } = await getWidgetMappingInfoDashboardWidgetV8ControllerAxios({
        dashboardId,
        startScreen: false,
        frameName: FRAME_NAMES.DASHBOARD.DASHBOARD_MAPPING_INFO,
      });
      widgetsForMonitoringDashboard.value = data.data?.[0] ?? {};
    } catch (e) {
      console.log(e);
      widgetsForMonitoringDashboard.value = {};
    }
  };

  const fetchMonitoringDashboard = async (dashboardId: number) => {
    await fetchCurrentDashboardInfo(dashboardId);
    await fetchWidgetsForMonitoringDashboard(dashboardId);
  };

  const selectedDetailInfo = ref<TriggerDetailInfo | null>(null);

  const isShowSummaryDetailSlide = ref<boolean>(false);
  const summaryDetailData = ref<SummaryDetailData | null>(null);

  const initSelectedDetailInfo = () => {
    selectedDetailInfo.value = null;
  };

  const setSelectedDetailInfo = (detailInfo: TriggerDetailInfo) => {
    selectedDetailInfo.value = detailInfo;
    isShowSummaryDetailSlide.value = false;
    summaryDetailData.value = null;
  };

  const initSummaryDetailSlide = () => {
    isShowSummaryDetailSlide.value = false;
    summaryDetailData.value = null;
    selectedDetailInfo.value = null;
  };

  const setSummaryDetailData = (data: SummaryDetailData) => {
    summaryDetailData.value = data;
    selectedDetailInfo.value = null;
    isShowSummaryDetailSlide.value = true;
  };

  const dashboardIdByRouter = ref<string>('');
  const changeIdByRouter = (id) => {
    dashboardIdByRouter.value = id;
  };

  return {
    monitoringDashboard,
    widgetsForMonitoringDashboard,
    fetchMonitoringDashboard,

    isShowSummaryDetailSlide,
    summaryDetailData,
    setSummaryDetailData,
    initSummaryDetailSlide,

    selectedDetailInfo,
    initSelectedDetailInfo,
    setSelectedDetailInfo,

    dashboardIdByRouter,
    changeIdByRouter,

    toggleShowTimePeriod,
    setGlobalPeriodRange,
  };
});
