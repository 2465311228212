export const POPUP_MIN_SIZE = {
  WIDTH: 1200,
  HEIGHT: 700,
};
export const SYSTEM_ALERT_GROUP_ID = '0';
export const USE_SLIDE_VIEW = {
  DASHBOARD: 'dashboard',
  DATABASE: 'database',
  HOST: 'host',
  CONTAINER: 'container',
  NETWORK_DEVICE: 'networkDevice',
  NETWORK_OBJECT: 'networkObject',
  KAFKA: 'kafka',
  AWS_DOCUMENTDB: 'awsDocumentdb',
  AWS_DYNAMODB: 'awsDynamodb',
  AWS_ELASTICACHE: 'awsElasticache',
  AWS_REDSHIFT: 'awsRedshift',
  AWS_DMS: 'awsDms',
  OBJECT_TOPOLOGY: 'objectTopology',
  CLUSTER_TOPOLOGY: 'clusterTopology',
  CLUSTER: 'cluster',
  POD: 'pod',
  NAMESPACE: 'namespace',
  NODE: 'node',
  DEPLOYMENT: 'deployment',
  STATEFUL_SET: 'statefulset',
  DAEMON_SET: 'daemonset',
  REPLICA_SET: 'replicaset',
  HPA: 'hpa',
  JOB: 'job',
  CRON_JOB: 'cronJob',
  NETWORK_SERVICE: 'service',
  WAS: 'was',
  TRANSACTION: 'transaction',
  URL_MONITORING: 'urlMonitoring',
  WEB_APPLICATION: 'webApplication',
  PAGE_PERFORMANCE_MONITORING: 'pagePerformanceMonitoring',
  USER_MONITORING: 'userMonitoring',
  BROWSER_ERROR_TRACKING: 'browserErrorTracking',
  INSTANCE: 'instance',
  BUSINESS_LIST: 'businessList',
  BUSINESS_MAP: 'businessMap',
  LOG_LOGGING: 'logging',
  LOG_LIVE_TAIL: 'liveTail',
} as const;

/**
 * /src/common/define/apiTrace.define.ts
 * @example 세 가지 필드가 있어야 함.
 *  DASHBOARD_ALERT_DETAIL: {
 *   ALERT_GROUP_AND_RULES: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Alert Group and Rules`,
 *   CLEAR_ALERT_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear User Alert`,
 *   CLEAR_SYSTEM_RULE: `${MAIN_FRAMES.DASHBOARD_ALERT_DETAIL}/Clear System Alert`,
 *  },
 */
export const FRAME_KEY = {
  [USE_SLIDE_VIEW.DASHBOARD]: 'DASHBOARD_ALERT_DETAIL',
  [USE_SLIDE_VIEW.DATABASE]: 'DATABASE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.HOST]: 'HOST_ALERT_DETAIL',
  [USE_SLIDE_VIEW.CONTAINER]: 'CONTAINER_ALERT_DETAIL',
  [USE_SLIDE_VIEW.NETWORK_DEVICE]: 'NETWORK_DEVICE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.NETWORK_OBJECT]: 'NETWORK_OBJECT_ALERT_DETAIL',
  [USE_SLIDE_VIEW.KAFKA]: 'KAFKA_ALERT_DETAIL',
  [USE_SLIDE_VIEW.AWS_DOCUMENTDB]: 'AWS_DOCUMENTDB_ALERT_DETAIL',
  [USE_SLIDE_VIEW.AWS_DYNAMODB]: 'AWS_DYNAMODB_ALERT_DETAIL',
  [USE_SLIDE_VIEW.AWS_ELASTICACHE]: 'AWS_ELASTICACHE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.AWS_REDSHIFT]: 'AWS_REDSHIFT_ALERT_DETAIL',
  [USE_SLIDE_VIEW.AWS_DMS]: 'AWS_DMS_ALERT_DETAIL',
  [USE_SLIDE_VIEW.OBJECT_TOPOLOGY]: 'K8S_OBJECT_TOPOLOGY_ALERT_DETAIL',
  [USE_SLIDE_VIEW.CLUSTER_TOPOLOGY]: 'K8S_CLUSTER_TOPOLOGY_ALERT_DETAIL',
  [USE_SLIDE_VIEW.CLUSTER]: 'K8S_CLUSTER_ALERT_DETAIL',
  [USE_SLIDE_VIEW.POD]: 'K8S_POD_ALERT_DETAIL',
  [USE_SLIDE_VIEW.NAMESPACE]: 'K8S_NAMESPACE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.NODE]: 'K8S_NODE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.DEPLOYMENT]: 'K8S_DEPLOYMENT_ALERT_DETAIL',
  [USE_SLIDE_VIEW.STATEFUL_SET]: 'K8S_STATEFUL_SET_ALERT_DETAIL',
  [USE_SLIDE_VIEW.DAEMON_SET]: 'K8S_DAEMON_SET_ALERT_DETAIL',
  [USE_SLIDE_VIEW.REPLICA_SET]: 'K8S_REPLICA_SET_ALERT_DETAIL',
  [USE_SLIDE_VIEW.HPA]: 'K8S_HPA_ALERT_DETAIL',
  [USE_SLIDE_VIEW.JOB]: 'K8S_JOB_ALERT_DETAIL',
  [USE_SLIDE_VIEW.CRON_JOB]: 'K8S_CRON_JOB_ALERT_DETAIL',
  [USE_SLIDE_VIEW.NETWORK_SERVICE]: 'K8S_SERVICE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.WAS]: 'WAS_ALERT_DETAIL',
  [USE_SLIDE_VIEW.TRANSACTION]: 'TRANSACTION_ALERT_DETAIL',
  [USE_SLIDE_VIEW.URL_MONITORING]: 'URL_MONITORING_ALERT_DETAIL',
  [USE_SLIDE_VIEW.WEB_APPLICATION]: 'WEB_APPLICATION_ALERT_DETAIL',
  [USE_SLIDE_VIEW.PAGE_PERFORMANCE_MONITORING]: 'PAGE_PERFORMANCE_MONITORING_ALERT_DETAIL',
  [USE_SLIDE_VIEW.USER_MONITORING]: 'USER_MONITORING_ALERT_DETAIL',
  [USE_SLIDE_VIEW.BROWSER_ERROR_TRACKING]: 'BROWSER_ERROR_TRACKING_ALERT_DETAIL',
  [USE_SLIDE_VIEW.INSTANCE]: 'INSTANCE_ALERT_DETAIL',
  [USE_SLIDE_VIEW.BUSINESS_LIST]: 'BUSINESS_LIST_ALERT_DETAIL',
  [USE_SLIDE_VIEW.BUSINESS_MAP]: 'BUSINESS_MAP_ALERT_DETAIL',
  [USE_SLIDE_VIEW.LOG_LOGGING]: 'LOG_LOGGING_ALERT_DETAIL',
  [USE_SLIDE_VIEW.LOG_LIVE_TAIL]: 'LOG_LIVE_TAIL_ALERT_DETAIL',
} as const;
