import dayjs from 'dayjs';
import { SelectedTagData } from '@/common/components/templates/selectTargetTagWindow/selectTargetTagWindow.type';
import { WidgetTarget } from '@/dashboard/utils/types';
import { RequestTargetItems, TagItem, WeeklyScheduleInfo } from '@/openapi/metaV6/model';
import {
  CommonRuleData,
  ConditionRowData,
  MetricSubset,
  Notification,
  NotificationItem,
  ProcessDownPattern,
  ProcessDownVariableCondition,
  WeeklySchedule,
} from '@/config/views/alertGroup/alertRuleWindow.types';
import { TargetTagType } from '@/common/utils/types';
import {
  ALERT_TYPE_VALUE,
  DEFAULT_TRANSPORT_SERVER_TYPE,
  EVENT_TYPE_ENUM,
  EVENT_VARIABLE_CONDITION_ITEMS,
  getEventTypeItems,
  getWeeklyScheduleItems,
  RULE_CRITERIA_ITEMS,
} from '@/alert/utils/define';
import { store } from '@/common/store';
import { ChartDataStat } from '@/common/components/organisms/statIndicatorSelect/statIndicatorSelect.types';
import { assign } from 'lodash-es';
import { PROCESS_DOWN_DEFAULT_PATTERN } from '@/config/views/alertGroup/alertRuleDataConditions/alertRuleDataConditionEventProcess.define';

export const getMetricSubset = (condition: { category?: string }): MetricSubset =>
  condition.category === 'process' ? 'process' : 'metric';

export const isK8sLoggingAlertCategory = ({ category }: { category?: string } = {}) =>
  ['deployment', 'cronjob', 'statefulset', 'daemonset'].includes(category ?? '');

// get init data
const defaultDayWeek = getWeeklyScheduleItems().map((item) => `${item.value}`);
const getDefaultWeeklySchedule = () => {
  const timeZone = store.getters['myInfo/getTimeZone'];
  return {
    dayWeek: defaultDayWeek,
    range: ['00:00', '23:59'],
    timeZone,
  };
};
export const getInitCommonRuleData = (): CommonRuleData => {
  return {
    type: ALERT_TYPE_VALUE.METRIC,
    ruleCriteria: RULE_CRITERIA_ITEMS[0].value,
    checkInterval: 5,
    checkRepeat: 3,
    maximumOccurrences: 1,
    checkHoldTime: 'p1m',
    weeklySchedule: getDefaultWeeklySchedule(),
  };
};

const getLoggingInitVariableCondition = () => ({
  loggingId: [],
  loggingName: [], // 수정 api 에서만 응답
  pathId: [],
});

const getProcessDownInitVariableCondition = (): ProcessDownVariableCondition => ({
  detectedPattern: [{ ...PROCESS_DOWN_DEFAULT_PATTERN }],
  excludePattern: [],
});

export const getInitVariableCondition = ({ kind }: { kind?: string } = {}) => ({
  facility: [EVENT_VARIABLE_CONDITION_ITEMS.facility[0].value],
  severity: [EVENT_VARIABLE_CONDITION_ITEMS.severity[0].value],
  genericTrapType: [EVENT_VARIABLE_CONDITION_ITEMS.genericTrapType[0].value],
  specificTrapCode: [],
  enterpriseOid: [],
  ...getLoggingInitVariableCondition(),
  kind: [kind ?? EVENT_VARIABLE_CONDITION_ITEMS.kind[0].value],
  eventId: [],
  ...getProcessDownInitVariableCondition(),
});

export const getInitConditionRowData = {
  metric: (): ConditionRowData['metric'] => ({
    isVisible: true,
    statInfo: undefined,
    category: '',
    targets: [],
    aggregation: 'sum',
    critical: null,
    criticalComparison: '>',
    warning: null,
    warningComparison: '',
    evaluateValue: 'avg',
    duringTimeInterval: 'p5m',
    unit: '',
    processName: '', // only process
  }),
  event: (
    data: {
      statInfo?: ChartDataStat;
      targets?: SelectedTagData[];
      eventType?: string;
      kind?: string;
    } = {},
  ): ConditionRowData['event'] => ({
    statInfo: data?.statInfo,
    eventType: data?.eventType ?? getEventTypeItems()[0].value,
    level: null,
    messageText: '',
    targets: data?.targets ?? [],
    postPeriod: null, // logging, process down detection
    variableCondition: getInitVariableCondition({
      kind: data?.kind,
    }),
  }),
};

export const writeContent = {
  title: '',
  content: '',
  fixedContent: '',
};

export const getInitNotificationItem = (type: string): NotificationItem => {
  if (['email', 'sms'].includes(type)) {
    return {
      ids: [],
      writeContent: { ...writeContent },
      transportServerType: DEFAULT_TRANSPORT_SERVER_TYPE[type],
    };
  }
  return {
    ids: [],
    writeContent: { ...writeContent },
  };
};
export const getInitNotification = (list: string[]): Notification => {
  if (!list?.length) {
    return {};
  }

  const tempObj: Notification = {};
  list.forEach((v) => {
    tempObj[v] = getInitNotificationItem(v);
  });
  tempObj.hasResolvedAlert = false;

  return tempObj;
};

const getTime = {
  forApi: (time: string): string => {
    return dayjs().format(`YYYY-MM-DD ${time}:00`);
  },
  forUi: (time: string): string => {
    return dayjs(time).format('HH:mm');
  },
};

export const convertWeeklySchedule = {
  forApi: (data: WeeklySchedule): WeeklyScheduleInfo => ({
    dayWeek: data.dayWeek.join(','),
    startTime: getTime.forApi(data.range[0]),
    endTime: getTime.forApi(data.range[1]),
    timeZone: data.timeZone ?? '',
  }),
  forUi: (data: WeeklyScheduleInfo, timeZone?: string): WeeklySchedule => {
    const defaultSchedule = getDefaultWeeklySchedule();
    if (!data) {
      return defaultSchedule;
    }
    return {
      dayWeek: data.dayWeek?.split(',').map((v) => v.trim()) ?? defaultSchedule.dayWeek,
      range:
        data.startTime && data.endTime
          ? [getTime.forUi(data.startTime!), getTime.forUi(data.endTime!)]
          : defaultSchedule.range,
      timeZone: (data.timeZone || timeZone) ?? defaultSchedule.timeZone,
    };
  },
};

export const convertTarget = {
  forUi: (data: TagItem): SelectedTagData => ({
    groupTagId: data?.tagKey ?? '',
    tagId: data?.tagId ?? '',
    tagType: (data?.tagType as TargetTagType) ?? 'tag',
  }),
  forApi: (data: SelectedTagData): RequestTargetItems => ({
    tagKey: data.groupTagId,
    tagType: data.tagType,
    tagValueId: data.tagId,
  }),
  forMetricApi: (data: SelectedTagData): WidgetTarget => ({
    tagKey: data.groupTagId,
    tagType: data.tagType,
    tagValue: data.tagId,
  }),
};

export const convertStatInfo = {
  forUi: (category: string, dataId: string): ChartDataStat | undefined => {
    if (category && dataId) {
      return {
        childCategory: category,
        statId: dataId,
      };
    }
    return undefined;
  },
  forApi: (data: ChartDataStat): { category: string; dataId: string } => ({
    category: data?.childCategory,
    dataId: data?.statId,
  }),
};

export const parseProcessDownPattern = (patternString: string) => {
  const [_, category, dataType, pattern] = patternString.split(/^([^:]*):([^:]*):(.*)$/);
  return {
    category,
    dataType,
    pattern,
  };
};

const convertProcessDownVariable = {
  forUi: (patterns: string[]) => (patterns || []).map(parseProcessDownPattern),
  forApi: (patterns: ProcessDownPattern[]) =>
    (patterns || []).map(({ category, dataType, pattern }) =>
      Object.values({
        category,
        dataType,
        pattern,
      }).join(':'),
    ),
};

export const convertVariableCondition = {
  forUi: (data, eventType) => {
    const variableCondition = assign(getInitVariableCondition(), data?.[0] ?? {});

    if (eventType === EVENT_TYPE_ENUM.PROCESS_DOWN_DETECTION) {
      const { detectedPattern, excludePattern } = variableCondition;
      return {
        ...variableCondition,
        detectedPattern: convertProcessDownVariable.forUi(detectedPattern),
        excludePattern: convertProcessDownVariable.forUi(excludePattern),
      };
    }

    return variableCondition;
  },
  forApi: (data, eventType) => {
    if (eventType === EVENT_TYPE_ENUM.LOGGING) {
      const { pathId, loggingId } = data;
      return [{ pathId, loggingId }];
    }

    if (eventType === EVENT_TYPE_ENUM.NETWORK_SYSLOG) {
      const { facility, severity } = data;
      return [{ facility, severity }];
    }

    if (eventType === EVENT_TYPE_ENUM.KUBERNETES) {
      const { kind, eventId } = data;
      return [{ kind, eventId }];
    }

    if (eventType === EVENT_TYPE_ENUM.PROCESS_DOWN_DETECTION) {
      const { detectedPattern, excludePattern } = data;
      return [
        {
          detectedPattern: convertProcessDownVariable.forApi(detectedPattern),
          excludePattern: convertProcessDownVariable.forApi(excludePattern),
        },
      ];
    }

    const { genericTrapType, specificTrapCode, enterpriseOid } = data;
    return [{ genericTrapType, specificTrapCode, enterpriseOid }];
  },
};
